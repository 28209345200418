<template>
    <div>
        <section class="first-section page404">
            <div class="container">
                <div class="row">
                    <div
                        class="col-10 offset-1 col-md-6 offset-md-3 text-center"
                    >
                        <img src="/img/404.svg" class="w-100" />
                        <p>
                            Hoppá! Úgy tűnik a keresett oldal nem található,
                            törölték, vagy hibás URL lett megadva. Gyere és nézz
                            szét nálunk, biztosan megtalálod, amit kerestél!
                        </p>
                        <router-link
                            :to="{ name: 'Home' }"
                            class="btn btn-primary"
                            >Főoldal
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "404"
};
</script>

<style scoped></style>
